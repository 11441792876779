.button {
    text-decoration: none;
    color: white;
    margin: 0 1em;
}

.title{
    text-align: right;
    align-self: right;
    flex-grow: 1;
    padding: .5em;
}

