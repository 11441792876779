.projects-card {
    padding: 4rem 3rem;
    margin: 2rem auto;
    
  }
  
  .divider {
    margin: 1rem 0 2rem;
  }
  
  .overview {
    margin: 1rem 0 2rem;
  }
  
  .project-link {
    text-decoration: none;
  }
  
  .project-box {
    border-radius: 1rem;
    padding: 1rem;
    margin: auto;
  }
  
.project-box:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.project-box:active {
    background-color: rgba(255, 255, 255, 0.2);
}